import React from "react";

// Assets
import { ReactComponent as CoinBorder } from "../../assets/svg/coin-border.svg";
import { ReactComponent as TeamCardCurve } from "../../assets/svg/team-card-curve.svg";

const bearBullTeam = [
  {
    nickname: "Pooria",
    title: "Founder",
    bio: `Multidisciplinary Creative, 
    Notorious NFT Gas Warrior 250 ETH Burnt, 
    Ex @Lvcidia`,
    image: "/assets/bear-bull-game/pooria.png",
    memberTwitter: "https://twitter.com/0xPooria",
  },
  {
    nickname: "RetroByte",
    title: "Lab",
    bio: "Full Stack Web3 Studio Combining Novel Tech & Nostalgic Art",
    image: "/assets/bear-bull-game/retrobyte.png",
    memberTwitter: "https://twitter.com/R3troByte",
  },
  {
    nickname: "Carnation",
    title: "Community",
    bio: "Former Overwatch Professional & Team Manager Turned Web3 Enthusiast",
    image: "/assets/bear-bull-game/Carnation.png",
    memberTwitter: "https://twitter.com/PooriaIntern",
  },
  {
    nickname: "VainGlorious",
    title: "Advisor",
    bio: `Founder of @WestCoastNFT Tech Lab of Doodles, Mfers, Nakamigos & More`,
    image: "/assets/bear-bull-game/VainGlorious.png",
    memberTwitter: "https://twitter.com/VaingloriousETH",
  },
];

const BearBullTeam = () => {
  return (
    <section className="bear-bull-team panel">
      <p className="bear-bull-title">[ THE CORE TEAM ]</p>

      <div className="cards-wrapper">
        {bearBullTeam.map((member, index) => (
          <div key={member?.nickname} className="team-card-wrapper">
            <div className="team-card-top">
              <div className="member-title-wrapper">
                <TeamCardCurve className="team-card-curve" />
                <p className="member-title">{member?.title}</p>
              </div>
              <img
                src={member?.image}
                alt=""
                className="member-image"
                draggable="false"
              />
            </div>

            <div className="team-card-bottom">
              <div className="member-team-name-wrapper">
                <p className="member-name">{member?.nickname}</p>
              </div>

              <p className="member-bio">{member?.bio}</p>
            </div>

            <div
              onClick={() => {
                window.open(member?.memberTwitter, "_blank");
              }}
              className="member-social-btn"
            >
              <img
                src="/assets/bear-bull-game/team-twitter.png"
                draggable="false"
                alt=""
              />
            </div>
          </div>
        ))}
      </div>

      <div className="coin-border-wrapper">
        <CoinBorder className="coin-border" />
        <img
          className="coin-border-icon"
          src="/assets/bear-bull-game/coin.gif"
          draggable="false"
          alt=""
        />
      </div>
    </section>
  );
};

export default BearBullTeam;
