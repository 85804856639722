import React, { useEffect, useRef, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useMediaQuery } from "react-responsive";

// Context
import { MainContext } from "../../components/context/context";

// Sections
import BearBullChoose from "./BearBullChoose";
import BearBullType from "./BearBullType";
import BearBullAllowlist from "./BearBullAllowlist";
import BearBullTeam from "./BearBullTeam";
import BearBullRoadmap from "./BearBullRoadmap";
import BearBullEcosystem from "./BearBullEcosystem";

// Components
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";

// Assets
import { ReactComponent as NavSvg } from "../../assets/svg/nav-svg.svg";
import { ReactComponent as VolumeOn } from "../../assets/media/icons/volumeOn.svg";
import { ReactComponent as VolumeOff } from "../../assets/media/icons/volumeOff.svg";
import { ReactComponent as ScrollToTopReversed } from "../../assets/svg/scroll-to-reversed.svg";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const BearBullLanding = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isSmallerDevice = useMediaQuery({ query: "(max-width: 1024px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 950px)" });

  const containerRef = useRef();
  const [isNavActive, setIsNavActive] = useState(false);
  const { isSoundEnabled, setIsSoundEnabled } = useContext(MainContext);

  const [currentSection, setCurrentSection] = useState(0);
  const sectionRefs = useRef([]);
  const isScrollingRef = useRef(false);

  const handleScrollTop = () => {
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5,
      onComplete: () => {
        setCurrentSection(0);
      },
    });
  };

  useEffect(() => {
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5,
    });
    const triggerLanding = ScrollTrigger.create({
      trigger: ".bear-bull-roadmap",
      start: "top bottom",
      end: "top top",
      onEnter: () => {
        setIsNavActive(true);
      },
      onLeaveBack: () => {
        setIsNavActive(false);
      },
    });

    return () => {
      // Cleanup
      triggerLanding.kill();
    };
  }, []);

  const scrollThreshold = 50; // Pixels

  useEffect(() => {
    if (isMobile) return;

    let accumulatedScroll = 0;

    const onScroll = (event) => {
      if (isScrollingRef.current) return;

      accumulatedScroll += event.deltaY;

      if (Math.abs(accumulatedScroll) > scrollThreshold) {
        isScrollingRef.current = true;

        const direction = accumulatedScroll > 0 ? 1 : -1;
        let nextSection = currentSection + direction;
        nextSection = Math.max(
          0,
          Math.min(nextSection, sectionRefs.current.length - 1)
        );

        setCurrentSection(nextSection);
        accumulatedScroll = 0;

        gsap.to(window, {
          scrollTo: { y: sectionRefs.current[nextSection], autoKill: false },
          duration: 0.75,
          onComplete: () => {
            isScrollingRef.current = false;
          },
        });
      }
    };

    window.addEventListener("wheel", onScroll);

    return () => {
      window.removeEventListener("wheel", onScroll);
    };
  }, [currentSection]);
  
  // useEffect(() => {
  //   const disableScroll = () => {
  //     if (isScrollingRef.current) {
  //       window.scrollTo(0, window.scrollY);
  //     }
  //   };

  //   window.addEventListener("scroll", disableScroll);

  //   return () => {
  //     window.removeEventListener("scroll", disableScroll);
  //   };
  // }, [isScrollingRef.current]);

  // useEffect(() => {
  //   if (isMobile) return;
  //   const disableScroll = (event) => event.preventDefault();
  //   window.addEventListener("scroll", disableScroll);
  //   return () => window.removeEventListener("scroll", disableScroll);
  // }, []);

  return (
    <>
      <div>
        <div ref={(el) => (sectionRefs.current[0] = el)}>
          <BearBullChoose setCurrentSection={setCurrentSection} />
        </div>

        <div ref={containerRef} className="bear-bull-page">
          <div className="background-element" />

          {/* <div ref={(el) => (sectionRefs.current[1] = el)}>
            <BearBullRoadmap />
          </div> */}

          <div ref={(el) => (sectionRefs.current[1] = el)}>
            <BearBullEcosystem />
          </div>

          {/* <div ref={(el) => (sectionRefs.current[2] = el)}>
            <BearBullType />
          </div>

          <div ref={(el) => (sectionRefs.current[3] = el)}>
            <BearBullAllowlist />
          </div> */}

          <div ref={(el) => (sectionRefs.current[2] = el)}>
            <BearBullTeam />
          </div>

        </div>
      </div>
      <button onClick={handleScrollTop} className="scroll-to-top">
        <ScrollToTopReversed />
      </button>
    </>
  );
};

export default BearBullLanding;
